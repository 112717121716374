import React, { memo } from "react"
import "./ProtocolDropdown.scss"
import {
  getProtocolBatteries,
  getProtocolScreeners,
  getProtocolClockDrawings,
  getMinimumDuration,
  getMaximumDuration,
} from "./protocol_utils"
import clockIcon from "../../../../assets/images/clock.png"
import radioUncheckedIcon from "../../../../assets/images/radio-unchecked.svg"
import radioCheckedIcon from "../../../../assets/images/radio-checked.svg"
import dropdownArrow from "../../../../assets/images/dropdown-arrow.svg"

/* 
props
  checked
  onChange
  protocol
    id
    name
    steps
*/

const DropdownBody = memo(props => {
  const protocol = props.protocol ?? {}

  const protocolBatteries = getProtocolBatteries(protocol.steps || [])
  const protocolScreeners = getProtocolScreeners(protocol.steps || [])
  const protocolClockDrawings = getProtocolClockDrawings(protocol.steps || [])
  const minimumDuration = getMinimumDuration([...protocolBatteries, ...protocolScreeners, ...protocolClockDrawings])
  const maximumDuration = getMaximumDuration([...protocolBatteries, ...protocolScreeners, ...protocolClockDrawings])
  return (
    <div className="row protocol-body">
      <div className="col-xs-12">
        <div className="protocol-duration">
          {protocolBatteries.length + protocolScreeners.length + protocolClockDrawings.length > 0 && (
            <div className="clock">
              <img alt="Clock Icon" src={clockIcon} />
              <span>
                {minimumDuration}-{maximumDuration} minutes
              </span>
            </div>
          )}
        </div>
        <ul>
          {protocolBatteries.length > 0 &&
            protocolBatteries.map((s, index) => {
              return (
                <li key={index}>
                  <span className="assesment-description-title">{s.step_title}: </span>
                  {s.step_assessments}
                </li>
              )
            })}
          {protocolScreeners.length > 0 && (
            <li>
              <span className="assesment-description-title">Screeners: </span>
              {protocolScreeners.map(s => s.step_title).join(", ")}
            </li>
          )}
          {protocolClockDrawings.length > 0 && (
            <li>
              <span className="assesment-description-title">Clock Drawings: </span>
              {protocolClockDrawings.map(s => s.step_title).join(", ")}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
})

const DropdownHeader = ({ checked, onChange, ...props }) => {
  const protocol = props.protocol ?? {}

  return (
    <div className="row protocol-header">
      <div className={`${props.protocol ? "col-xs-10" : "col-xs-12"} default-protocol-radio-button`}>
        <label className="pull-left">
          <input
            type="radio"
            value={protocol.id}
            name="default-protocol-selection-field"
            checked={checked}
            onChange={onChange}
          />
          <img className="radio-icon-checked" src={radioCheckedIcon} />
          <img className="radio-icon-unchecked" src={radioUncheckedIcon} />

          <span className="protocol-title">{protocol.name ?? "None"}</span>
        </label>
      </div>
      {props.protocol && (
        <div className="col-xs-2 dropdown-toggle">
          <label className="pull-right">
            <input type="checkbox" />
            <img src={dropdownArrow} />
            {/* <i className="fa fa-chevron-down "></i> */}
          </label>
        </div>
      )}
    </div>
  )
}

export default props => {
  return (
    <div className="protocol-dropdown-container">
      <DropdownHeader {...props} />
      {props.protocol && <DropdownBody protocol={props.protocol} />}
    </div>
  )
}
