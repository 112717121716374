import React, { useState } from "react"
import SelectProvider from "../select_provider"
import CreateProvider from "../create_provider"
import "../../intl/i18n"

export default function SelectProviderModal({ providers, resultId, refreshPage }) {
  const [displayCreateProvider, changeDisplayCreateProvider] = useState(false)

  return (
    <div>
      {displayCreateProvider ? (
        <CreateProvider
          resultId={resultId}
          changeDisplayCreateProvider={changeDisplayCreateProvider}
          refreshPage={refreshPage}
        />
      ) : (
        <SelectProvider
          providers={providers}
          resultId={resultId}
          changeDisplayCreateProvider={changeDisplayCreateProvider}
          refreshPage={refreshPage}
        />
      )}
    </div>
  )
}
