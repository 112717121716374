import Cookies from "js-cookies"

const getAuthToken = () => {
  const token = Cookies.getItem("_bc_jwt")
  if (token) return token
  return null
}

const setHeaders = () => {
  return { "Content-Type": "application/json", Authorization: `Bearer ${getAuthToken()}` }
}

const handleRespone = response => {
  if (response.status === 204) {
    return response.body
  }

  if (response.ok) {
    return response.json()
  } else {
    const error = new Error(response.statusText)
    error.response = parseResponse(response)
    throw error
  }
}

const handleError = response => {
  throw response
}

const parseResponse = response => {
  if (response.status === 204) {
    return ""
  } else {
    return response.json()
  }
}

const Artemis = {
  post: (data, url) => {
    return fetch(url, {
      method: "POST",
      headers: setHeaders(),
      body: JSON.stringify(data),
    }).then(handleRespone)
  },
  patch: (data, url) => {
    return fetch(url, {
      method: "PATCH",
      headers: setHeaders(),
      body: JSON.stringify(data),
    })
      .then(handleRespone)
      .catch(handleError)
  },
  get: url => {
    return fetch(url, {
      method: "GET",
      headers: setHeaders(),
    }).then(handleRespone)
  },
}

export { Artemis }
