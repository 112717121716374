import "./CreateProvider.css"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Artemis } from "../../helpers/Artemis/Artemis"
import TextInput from "../inputs/TextInput"
import handleAdvance from "../forms/helpers/handleAdvance"
import icnCheckmark from "../../assets/images/icn-checkmark.svg"

export default function CreateProvider({ resultId, changeDisplayCreateProvider, refreshPage }) {
  const { t } = useTranslation()

  const [isDisabled, changeIsDisabled] = useState(true)
  const [provider, changeProvider] = useState({})
  const [isSaved, changeIsSaved] = useState(false)
  const [requiredFields, changeRequiredFields] = useState({ firstName: false, lastName: false })

  useEffect(() => {
    handleAdvance(requiredFields, changeIsDisabled)
  }, [requiredFields])

  const restoreDefaults = () => {
    changeIsDisabled(true)
    changeIsSaved(false)
    changeRequiredFields({ firstName: false, lastName: false })
    changeProvider({})
  }

  const returnToSelect = () => {
    restoreDefaults()
    changeDisplayCreateProvider(false)
  }

  const closeModal = () => {
    let modal = $("#select-provider.modal")
    restoreDefaults()
    modal.closeModal()
    if (refreshPage) {
      location.reload()
    }
  }

  const assignProvider = provider => {
    Artemis.patch({ result: { provider_id: provider.id } }, `/api/v1/results/${resultId}`)
      .then(() => {
        changeIsSaved(true)
        let aTagNode = $(`#provider-${resultId}`)
        aTagNode.replaceWith(
          `<span id="provider-${resultId}">${provider.name_prefix ? `${provider.name_prefix} ` : ""}${
            provider.first_name
          } ${provider.last_name}</span>`
        )
        setTimeout(closeModal, 2000)
      })
      .catch(e => console.log("E is: ", e))
  }

  const addAndAssignProvider = () => {
    Artemis.post(provider, "/api/v1/providers")
      .then(response => assignProvider(response.provider))
      .catch(e => console.log("E is: ", e))
  }

  return (
    <div>
      <h5 className="select-provider-header"> {t("selectProvider.title")} </h5>
      <p className="select-provider-text">{t("createProvider.text")} </p>
      <TextInput
        labelText={t("createProvider.formFields.title")}
        placeHolderText={t("createProvider.formPlaceholders.title")}
        inputName="title"
        postName="name_prefix"
        sizeClass="full-width"
        changePostObject={changeProvider}
        changeRequiredFields={changeRequiredFields}
      />
      <TextInput
        required
        labelText={t("createProvider.formFields.firstName")}
        placeHolderText={t("createProvider.formPlaceholders.firstName")}
        inputName="firstName"
        postName="first_name"
        sizeClass="full-width"
        changePostObject={changeProvider}
        changeRequiredFields={changeRequiredFields}
      />
      <TextInput
        required
        labelText={t("createProvider.formFields.lastName")}
        placeHolderText={t("createProvider.formPlaceholders.lastName")}
        inputName="lastName"
        postName="last_name"
        sizeClass="full-width"
        changePostObject={changeProvider}
        changeRequiredFields={changeRequiredFields}
      />
      <div className="select-provider-row">
        <a type="button" onClick={returnToSelect} className="provider-button-link">
          {t("createProvider.back")}
        </a>
        <button
          type="button"
          onClick={isSaved ? closeModal : addAndAssignProvider}
          className={`provider-button ${isDisabled ? "disabled" : ""}`}
        >
          {t("createProvider.addAndAssign")}
          {isSaved && <img className="confirmation-check" src={icnCheckmark} />}
        </button>
      </div>
    </div>
  )
}
