import "./TextInput.css"
import React, { useState } from "react"
import booleanStateChangeWrapper from "../../forms/helpers/booleanStateChangeWrapper"

export default function TextInput({
  labelText,
  inputName,
  placeHolderText,
  postName,
  required,
  sizeClass,
  changePostObject,
  changeRequiredFields,
  recurlyData,
}) {
  const [error, setError] = useState(false)

  const handleOnChange = e => {
    const { value } = e.target
    if (changePostObject) {
      changePostObject(prevState => ({ ...prevState, [postName]: value }))
    }

    if (required && value !== "") {
      booleanStateChangeWrapper(changeRequiredFields, inputName, true)
    }
  }

  const handleBlur = e => {
    const { value } = e.target

    if (required && value === "") {
      setError(true)
      booleanStateChangeWrapper(changeRequiredFields, inputName, false)
    }

    if (error && value !== "") {
      setError(false)
    }

    if (required && value !== "") {
      booleanStateChangeWrapper(changeRequiredFields, inputName, true)
    }
  }

  return (
    <div className={`text-input-container ${sizeClass}`}>
      <label htmlFor={inputName} data-testid="text-label" className={`text-label ${error ? "error" : ""}`}>
        {labelText}
        {required ? (
          <em data-testid="required-indication" className={`required-indication ${error ? "error" : ""}`}>
            *
          </em>
        ) : null}
      </label>
      <input
        type="text"
        data-testid="text-input"
        data-recurly={recurlyData}
        className={`text-input ${error ? "error" : ""}`}
        id={inputName}
        name={inputName}
        placeholder={placeHolderText}
        onChange={e => handleOnChange(e)}
        onBlur={e => handleBlur(e)}
      />
    </div>
  )
}
