import "./test_taker_form.css"
import "react-phone-input-2/lib/style.css"

import { Controller, useForm } from "react-hook-form"
import React, { useState } from "react"
import Select, { components } from "react-select"

import { Artemis } from "../../helpers/Artemis/Artemis"
import DatePicker from "react-date-picker"
import Modal from "react-modal"
import PhoneInput from "react-phone-input-2"
import downCaret from "../../assets/images/down-caret.svg"

const customStyles = {
  control: provided => ({ ...provided, borderRadius: "0.25rem", border: "1px solid #979797" }),
  container: provided => ({ ...provided, width: "100%" }),
  option: (provided, { isFocused, isSelected }) => {
    return {
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? "#cd2a8a" : isFocused ? "#f1c8e2" : null,
    }
  },
  placeholder: provided => ({ ...provided, color: "#c1c1c1" }),
  indicatorSeparator: () => ({ display: "none" }),
  input: provided => ({ ...provided, margin: "0" }),
  valueContainer: provided => ({ ...provided, padding: "0 1rem" }),
}

const statusStyle = {}
Object.assign(statusStyle, customStyles)

statusStyle.control = provided => ({ ...provided, borderRadius: "6px", border: "1px solid #000000" })

const DropdownIndicator = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <img style={{ height: "10px", width: "10px" }} src={downCaret} alt="caret pointing down" />
    </components.DropdownIndicator>
  )
}

const returnRubyIds = (rubyObjectArray, arr) => {
  arr = keysFromCheckboxReturn(arr)
  if (arr.length === 0) {
    return arr
  } else {
    return objectIdFromString(rubyObjectArray, arr)
  }
}

const keysFromCheckboxReturn = arr => {
  return arr
    .filter(obj => Object.keys(obj).some(key => obj[key] === true))
    .map(obj => Object.keys(obj))
    .flat()
}

const objectIdFromString = (rubyObjectArray, arr) => {
  let id_arr = rubyObjectArray.flatMap(element => {
    if (arr.includes(element.name)) {
      return element.id
    } else {
      return null
    }
  })
  return id_arr.filter(elem => {
    return elem
  })
}

const selectedGroup = (elementID, userGroups, defaultGroupId) => {
  if (userGroups.includes(elementID) || elementID === defaultGroupId) {
    return true
  } else {
    return false
  }
}

const intakeDOB = dob => {
  if (dob === null || dob === "") {
    return ""
  } else {
    let newDOB = new Date(Date.parse(`${dob}T12:00:00.0000`))
    return newDOB
  }
}

const postPath = basePath => {
  return `${basePath}/api/v1/test_takers`
}

const patchPath = (id, basePath) => {
  return `${basePath}/api/v1/test_takers/${id}`
}

const getPath = (params, basePath) => {
  return `${basePath}/api/v1/test_takers/search${params}`
}

const minimumDateOfBirth = () => {
  let date = new Date()
  date.setFullYear(date.getFullYear() - 119)
  return date
}

const backLinkPath = (basePath, clientBasePath, testTakerId) => {
  let path = ""
  if (localStorage["ember_simple_auth-session"]) {
    path = testTakerId ? `/users/${testTakerId}` : "/users"
    return `${clientBasePath}${path}`
  } else {
    path = testTakerId ? `/test_takers/${testTakerId}` : "/test_takers"
    return `${basePath}${path}`
  }
}

const showUserUrl = (basePath, clientBasePath, id) => {
  if (localStorage["ember_simple_auth-session"]) {
    return `${clientBasePath}/users/${id}`
  } else {
    return `${basePath}/test_takers/${id}`
  }
}

const determineStartingSelectValue = (valueId, options) => {
  if (valueId === "0" || valueId === 0 || valueId === null) {
    return null
  }
  return options.filter(option => option.value === valueId)
}

const determineTestingLanguageValue = (valueId, options) => {
  if (!valueId) {
    return "en"
  }
  return options.filter(option => option.value === valueId)
}

const TestTakerForm = props => {
  // Default form values
  let currentDOB = ""
  let currentEducationLevel = 0
  let currentGender = ""
  let currentMobileNumber = ""
  let currentNativeLanguage = 0
  let currentStatus = ""
  let firstName = ""
  let hispanic = null
  let lastName = ""
  let preferredLanguage = ""
  let testTakerID = ""
  let userEthnicities = []
  let userGroups = []

  const cancelLinkLocation = localStorage["ember_simple_auth-session"] ? "/#/users" : "/test_takers"

  const cancelForm = () => {
    closeModal()
    window.location = cancelLinkLocation
  }

  // Image stuff
  const emberAssetPath = "assets/images"
  const railsAssetPath = "/assets"

  const VectorIconPath = localStorage["ember_simple_auth-session"]
    ? `${emberAssetPath}/vector.svg`
    : `${railsAssetPath}/vector.svg`

  const PlusIconLocation = localStorage["ember_simple_auth-session"]
    ? `${emberAssetPath}/plus_icon.svg`
    : `${railsAssetPath}/plus_icon.svg`

  const MinusIconPath = localStorage["ember_simple_auth-session"]
    ? `${emberAssetPath}/minus_icon.svg`
    : `${railsAssetPath}/minus_icon.svg`

  const TooltipIconPath = localStorage["ember_simple_auth-session"]
    ? `${emberAssetPath}/tooltip_icon.svg`
    : `${railsAssetPath}/tooltip_icon.svg`

  // Prefilled test taker data
  if (props.test_taker.id) {
    firstName = props.test_taker.first_name || ""
    lastName = props.test_taker.last_name || ""
    testTakerID = props.test_taker.test_taker_id || ""
    currentMobileNumber = props.test_taker.mobile_number || ""
    userGroups = props.test_taker.belongs_to_groups || []
    currentDOB = intakeDOB(props.test_taker.date_of_birth)
    currentGender = props.test_taker.gender || ""
    hispanic = props.test_taker.latino
    preferredLanguage = props.test_taker.preferred_language || ""
    userEthnicities = props.test_taker.ethnicity_ids || []
    currentNativeLanguage = props.test_taker.language_id || 0
    currentEducationLevel = props.test_taker.education_level_id || 0
    currentStatus = props.test_taker.status || ""
  }

  const { register, errors, getValues, setError, clearError, handleSubmit, control } = useForm({
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      test_taker_id: testTakerID,
      mobile_number: mobileNumber,
    },
    mode: "onBlur",
  })

  // Set a time to ten years ago to compare with our date of birth value
  // Allows us to display warning for users under ten
  let tenYearsAgo = new Date()
  tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10)

  const dateOfBirthErrorMessage = "Valid date of birth required"
  const educationLevelIdErrorMessage = "Please make a selection"
  const ethnicityIdsErrorMessage = "Please check all that apply"
  const genderErrorMessage = "Please make a selection"
  const languageIdErrorMessage = "Please make a selection"
  const latinoErrorMessage = "Please make a selection"
  const mobileNumberErrorMessage = "Mobile number is invalid"
  const testTakerErrorMessage = "Test taker ID already in use"
  const underTenErrorMessage = "BrainCheck is validated for use in subjects 10 years or older."
  const zipCodeErrorMessage = "Zip code cannot be blank"

  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const sexOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Prefer not to say", value: "Other" },
  ]

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]

  const hispanicOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const testingLanguageOptions = [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
  ]

  const [basePath] = useState(props.form_data.base_path)
  const [clientBasePath] = useState(props.form_data.client_base_path)
  const [groups] = useState(props.form_data.groups)
  const [ethnicities] = useState(props.form_data.ethnicities)
  const [languages] = useState(
    props.form_data.languages.map(language => {
      return { label: language.name, value: language.id }
    })
  )
  const [educationLevels] = useState(
    props.form_data.education.map(ed => {
      return { label: ed.name, value: ed.id }
    })
  )

  const [dateOfBirth, changeDateOfBirth] = useState(currentDOB)
  const [mobileNumber, changeMobileNumber] = useState(currentMobileNumber)
  const [dateOfBirthErrors, changeDateOfBirthErrors] = useState("")
  const [underTenError, changeUnderTenError] = useState("")

  const [activeSex, changeActiveSex] = useState(determineStartingSelectValue(currentGender, sexOptions))
  const [genderErrors, changeGenderErrors] = useState("")

  const [activeTestingLanguage, changeActiveTestingLanguage] = useState(
    determineTestingLanguageValue(preferredLanguage, testingLanguageOptions)
  )

  const [activeHispanic, changeActiveHispanic] = useState(determineStartingSelectValue(hispanic, hispanicOptions))

  const [activeLanguage, changeActiveLanguage] = useState(
    determineStartingSelectValue(currentNativeLanguage, languages)
  )

  const [activeEducation, changeActiveEducation] = useState(
    determineStartingSelectValue(currentEducationLevel, educationLevels)
  )

  const [zipCode, changeZipCode] = useState(props.test_taker.zip_code || "")

  const [testTakerIdError, changeTestTakerError] = useState("")
  const [mobileNumberError, changeMobileNumberError] = useState("")

  const [loginToggled, toggleLogin] = useState(false)
  const [loginTooltipVisible, toggleLoginTooltip] = useState(false)
  const [showConfirmation, toggleShowConfirmation] = useState(false)
  const [activeSubmit, changeActiveSubmit] = useState(false)

  const [searchFirst, changeSearchFirst] = useState("")
  const [searchLast, changeSearchLast] = useState("")
  const [searchResults, changeSearchResults] = useState([])
  const [dobSearchResults, changeDobSearchResults] = useState([])
  const [dobValue, changeDOBValue] = useState(currentDOB)

  const [selectedStatus, changeStatus] = useState(determineStartingSelectValue(currentStatus, statusOptions))

  const changeDOB = date => {
    if (date instanceof Date) {
      changeDateOfBirth(date)
      changeDOBValue(date)
      tenYearsAgo - date < 0 ? changeUnderTenError(underTenErrorMessage) : changeUnderTenError("")
      changeDateOfBirthErrors("")
    } else {
      changeDateOfBirth("")
      changeDateOfBirthErrors(dateOfBirthErrorMessage)
    }
  }

  const handleDOBBlur = () => {
    if (isNaN(new Date(dateOfBirth).getTime())) return
    dateOfBirthErrors ? changeDOBValue("") : search()
  }

  const search = () => {
    if (searchFirst.length < 1 && searchLast.length < 2) {
      return
    }
    let url = `?first_name=${searchFirst}&last_name=${searchLast}`

    if (dateOfBirth) {
      url = `?first_name=${searchFirst}&last_name=${searchLast}&dob=${JSON.stringify(dateOfBirth)}`
    }

    Artemis.get(getPath(url, basePath)).then(response => {
      if (dateOfBirth) {
        changeDobSearchResults(response.data)
      } else {
        changeSearchResults(response.data)
      }
    })
  }

  const routeToUserShow = id => {
    window.location = showUserUrl(basePath, clientBasePath, id)
  }

  const [educationLevelIdError, setEducationLevelIdError] = useState("")
  const [ethnicityIdsError, setEthnicityIdsError] = useState("")
  const [languageIdError, setLanguageIdError] = useState("")
  const [latinoError, setLatinoError] = useState("")
  const [zipCodeError, setZipCodeError] = useState("")

  const _isEmpty = value => {
    return value == null || value === "" || (Array.isArray(value) && !value.length)
  }

  const _nothingSelected = value => {
    return _isEmpty(value) || value === "0" || value === 0
  }

  const validateEducationLevelId = () => {
    const hasActiveEducation = !_nothingSelected(activeEducation)
    setEducationLevelIdError(() => (!hasActiveEducation ? educationLevelIdErrorMessage : ""))
    return hasActiveEducation
  }

  const validateEthnicityIds = () => {
    const hasEthnicities = ethnicities.some(
      (ethnicity, index) => getValues()[`ethnicity_ids[${index}]["${ethnicity.name}"]`]
    )
    setEthnicityIdsError(() => (!hasEthnicities ? ethnicityIdsErrorMessage : ""))
    return hasEthnicities
  }

  const validateLanguageId = () => {
    const hasActiveLanguage = !_nothingSelected(activeLanguage)
    setLanguageIdError(() => (!hasActiveLanguage ? languageIdErrorMessage : ""))
    return hasActiveLanguage
  }

  const validateLatino = () => {
    const hasActiveHispanic = !_nothingSelected(activeHispanic)
    setLatinoError(() => (!hasActiveHispanic ? latinoErrorMessage : ""))
    return hasActiveHispanic
  }

  const validateZipCode = () => {
    const hasZipCode = !_isEmpty(zipCode)
    setZipCodeError(() => (!hasZipCode ? zipCodeErrorMessage : ""))
    return hasZipCode
  }

  const fieldsAreValid = () => {
    const validationResults = [
      validateEducationLevelId(),
      validateEthnicityIds(),
      validateLanguageId(),
      validateLatino(),
      validateZipCode(),
    ]
    return !validationResults.some(validationResult => !validationResult)
  }

  const onSubmit = data => {
    changeActiveSubmit(true)

    // BCE-594 | TURN OFF VALIDATION FOR NOW
    // if (!fieldsAreValid()) {
    //   changeActiveSubmit(false)
    //   return
    // }

    data.group_ids = returnRubyIds(groups, data.group_ids)
    data.ethnicity_ids = returnRubyIds(ethnicities, data.ethnicity_ids)
    data.gender = activeSex
    data.preferred_language = activeTestingLanguage
    data.latino = activeHispanic
    data.date_of_birth = dateOfBirth
    data.language_id = activeLanguage
    data.mobile_number = mobileNumber
    data.language_id = data.language_id === "0" ? null : data.language_id
    data.education_level_id = activeEducation === "0" ? null : activeEducation
    data.status = selectedStatus
    data.zip_code = zipCode
    let dataReturn = { test_taker: data }

    if (props.test_taker.id) {
      Artemis.patch(dataReturn, patchPath(props.test_taker.id, basePath))
        .then(() => {
          if (localStorage["ember_simple_auth-session"]) {
            window.location = `/#/users/${props.test_taker.id}`
          } else {
            window.location = `/test_takers/${props.test_taker.id}`
          }
        })
        .catch(errors => {
          changeActiveSubmit(false)
          if (errors instanceof TypeError) {
            alert("The Application is offline, please try again later.")
          } else {
            errors.response.then(errorMessage => {
              if (errorMessage.hasOwnProperty("date_of_birth")) {
                changeDateOfBirthErrors(dateOfBirthErrorMessage)
              }

              if (errorMessage.hasOwnProperty("test_taker_id")) {
                changeTestTakerError(testTakerErrorMessage)
              }

              if (errorMessage.hasOwnProperty("gender")) {
                changeGenderErrors(genderErrorMessage)
              }

              if (errorMessage.hasOwnProperty("mobile_number")) {
                changeMobileNumberError(mobileNumberErrorMessage)
              }
            })
          }
        })
    } else {
      Artemis.post(dataReturn, postPath(basePath))
        .then(response => {
          if (localStorage["ember_simple_auth-session"]) {
            window.location = `/#/users/${response.test_taker.id}`
          } else {
            window.location = `/test_takers/${response.test_taker.id}`
          }
        })
        .catch(errors => {
          changeActiveSubmit(false)
          if (errors instanceof TypeError) {
            alert("The Application is offline, please try again later.")
          } else {
            errors.response.then(errorMessage => {
              if (errorMessage.hasOwnProperty("date_of_birth")) {
                changeDateOfBirthErrors(dateOfBirthErrorMessage)
              }

              if (errorMessage.hasOwnProperty("test_taker_id")) {
                changeTestTakerError(testTakerErrorMessage)
              }

              if (errorMessage.hasOwnProperty("gender")) {
                changeGenderErrors(genderErrorMessage)
              }

              if (errorMessage.hasOwnProperty("mobile_number")) {
                changeMobileNumberError(mobileNumberErrorMessage)
              }
            })
          }
        })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="test-taker-form">
        <div className="test-taker-information flex-container">
          <div className="column-flex-container form-width">
            <h1 className="form-title">{props.test_taker.id ? "Edit Test Taker" : "Add Test Taker"}</h1>
            <div className="test-taker-header">
              <span className="section-identifier">Test Taker Information</span>
            </div>
            <div className="row-flex-container">
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="first_name">
                  First name
                </label>
                <input
                  className="test-taker-input"
                  onChange={e => changeSearchFirst(e.target.value)}
                  id="first-name"
                  name="first_name"
                  ref={register({
                    required: "Required",
                    pattern: /[^`&@\(\)/><\]\[“^]/,
                  })}
                />
                <div>
                  <span className="error-text">{errors.first_name && "First name cannot be blank"}</span>
                </div>
              </div>
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="last_name">
                  Last name
                </label>
                <input
                  className="test-taker-input"
                  onChange={e => changeSearchLast(e.target.value)}
                  onBlur={() => search()}
                  name="last_name"
                  id="last-name"
                  ref={register({
                    required: "Required",
                    pattern: /[^`&@\(\)/><\]\[“^]/,
                  })}
                />
                <div>
                  <span className="error-text">{errors.last_name && "Last name cannot be blank"}</span>
                </div>
              </div>
            </div>
            {searchResults && searchResults.length > 0 && (
              <div className="vertical-stack search-results-container">
                <h6 className="search-results-header">This name is already in use with other account(s).</h6>
                <ul>
                  {searchResults.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="row-flex-container search-results-item-container"
                        onClick={() => routeToUserShow(element.id)}
                      >
                        <div className="search-results-item name">{element.name}</div>
                        <div className="search-results-item">{element.dob}</div>
                        <div className="search-results-item">{element.gender}</div>
                        <div className="search-results-item">view</div>
                      </div>
                    )
                  })}
                </ul>
              </div>
            )}
            <div className="row-flex-container">
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="test_taker_id">
                  Test Taker ID
                  <span className="optional-indicator">(optional)</span>
                </label>
                <input ref={register} id="test-taker-id" className="test-taker-input" name="test_taker_id" />
                <div>
                  <span className="error-text">{testTakerIdError}</span>
                </div>
              </div>
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="mobile_number">
                  Mobile Number
                  <span className="optional-indicator">(optional)</span>
                </label>
                <PhoneInput
                  inputClass="test-taker-input"
                  inputProps={{
                    id: "mobile-number",
                    name: "mobile_number",
                  }}
                  country={"us"}
                  value={mobileNumber}
                  onChange={changeMobileNumber}
                  ref={register}
                />
                <div>
                  <span className="error-text">{mobileNumberError}</span>
                </div>
              </div>
            </div>

            {props.test_taker.id && (
              <div className="row-flex-container">
                <div className="vertical-stack side-by-side-width">
                  <label className="test-taker-label" htmlFor="status">
                    Status
                  </label>
                  <div name="testTakerStatus" className="button-group test-taker-select-input-container">
                    <Controller
                      as={<Select components={{ DropdownIndicator }} styles={statusStyle} options={statusOptions} />}
                      control={control}
                      isSearchable={false}
                      defaultValue={selectedStatus}
                      onChange={([selected]) => {
                        changeStatus(selected.value)
                      }}
                      name="status"
                      placeholder="Please select"
                    />
                  </div>
                  <div>
                    <span className="error-text"></span>
                    {/* keep this for styling consistency => adds 40px of empty space to the bottom of field */}
                  </div>
                </div>
              </div>
            )}
            <label htmlFor="test-taker-groups" className="test-taker-label">
              Groups (optional):
            </label>
            <div className="test-taker-groups">
              {groups.map((element, index) => {
                return (
                  <div className="checkbox" key={element.id}>
                    <input
                      ref={register}
                      name={`group_ids[${index}]["${element.name}"]`}
                      id={`group-${element.id}`}
                      className={`check_boxes ${
                        props.form_data.default_group.id === element.id ? "inactive" : "active"
                      }`}
                      type="checkbox"
                      defaultChecked={
                        selectedGroup(element.id, userGroups, props.form_data.default_group.id) ? true : false
                      }
                    />
                    <label
                      id={`label-group-${element.id}`}
                      className={`collection_check_boxes ${
                        props.form_data.default_group.id === element.id ? "inactive" : "active"
                      }`}
                      htmlFor={`group-${element.id}`}
                    >
                      {element.name}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="demographic-information flex-container">
          <div className="column-flex-container form-width">
            <div className="section-header">
              <div className="section-identifier">Demographic Information</div>
              <div className="subhead-text">
                Test scores are normed to each individual's age and sex. Additional demographic information (ethnicity,
                race, etc.) is used for research purposes only
              </div>
            </div>
            <div className="row-flex-container space-up">
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="testTakerDOB">
                  Date Of Birth
                </label>
                <DatePicker
                  name="date-of-birth"
                  onChange={changeDOB}
                  onBlur={() => handleDOBBlur()}
                  value={dobValue}
                  ref={register}
                  clearIcon={false}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  maxDate={new Date()}
                  minDate={minimumDateOfBirth()}
                  disableCalendar={true}
                />
                {dateOfBirth && dobSearchResults && dobSearchResults.length > 0 && (
                  <div className="vertical-stack search-results-container">
                    <h6 className="search-results-header">This name is already in use with other account(s).</h6>
                    <ul>
                      {dobSearchResults.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="row-flex-container search-results-item-container"
                            onClick={() => routeToUserShow(element.id)}
                          >
                            <li className="search-results-item name">{element.name}</li>
                            <li className="search-results-item">{element.dob}</li>
                            <li className="search-results-item">{element.gender}</li>

                            <a className="search-results-item">view</a>
                          </div>
                        )
                      })}
                    </ul>
                  </div>
                )}
                <div>
                  <span className="error-text">{dateOfBirthErrors || underTenError}</span>
                </div>
              </div>
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="testTakerSex">
                  Sex
                </label>
                <div name="testTakerSex" className="button-group test-taker-select-input-container">
                  <Controller
                    as={<Select components={{ DropdownIndicator }} styles={customStyles} options={sexOptions} />}
                    control={control}
                    isSearchable={false}
                    defaultValue={activeSex}
                    onChange={([selected]) => {
                      changeActiveSex(selected.value)
                    }}
                    name="selectSex"
                    placeholder="Please select"
                  />
                </div>
                <div>
                  <span className="error-text">{genderErrors}</span>
                </div>
              </div>
            </div>
            <div className="vertical-stack side-by-side-width">
              <label className="test-taker-label" htmlFor="testingLanguage">
                Testing Language
              </label>
              <div name="testingLanguage">
                <Controller
                  as={
                    <Select components={{ DropdownIndicator }} styles={customStyles} options={testingLanguageOptions} />
                  }
                  control={control}
                  isSearchable={false}
                  defaultValue={activeTestingLanguage}
                  onChange={([selected]) => {
                    changeActiveTestingLanguage(selected.value)
                  }}
                  name="testingLanguage"
                  placeholder="Please select"
                />
              </div>
              <div>
                <span className="error-text">{errors.preferred_language && "Please make a selection"}</span>
              </div>
            </div>
            <div className="vertical-stack">
              <label className="test-taker-label" htmlFor="hispanicOrigin">
                Is the test taker of Hispanic, Latino/a, or of Spanish origin?
                <span className="optional-indicator">(optional)</span>
              </label>
              <div name="hispanicOrigin" className="side-by-side-width test-taker-select-input-container">
                <Controller
                  as={<Select components={{ DropdownIndicator }} styles={customStyles} options={hispanicOptions} />}
                  control={control}
                  isSearchable={false}
                  defaultValue={activeHispanic}
                  onChange={([selected]) => {
                    changeActiveHispanic(selected.value)
                  }}
                  // onBlur={validateLatino} // BCE-594 | TURN OFF VALIDATION FOR NOW
                  name="hispanicOrigin"
                  placeholder="Please select"
                />
              </div>
              <div>
                <span className="error-text">{latinoError}</span>
              </div>
            </div>
            <label htmlFor="test-taker-ethnicity" className="test-taker-label space-up">
              What is the test taker's race? Please check all that apply
              <span className="optional-indicator">(optional)</span>
            </label>
            <div className="test-taker-ethnicity">
              {ethnicities.map((element, index) => {
                return (
                  <div className="checkbox" key={element.id}>
                    <input
                      ref={register}
                      name={`ethnicity_ids[${index}]["${element.name}"]`}
                      id={`ethnicity-${element.id}`}
                      className="check_boxes"
                      defaultChecked={userEthnicities.includes(element.id)}
                      type="checkbox"
                      // onBlur={validateEthnicityIds} // BCE-594 | TURN OFF VALIDATION FOR NOW
                    />
                    <label
                      id={`label-ethnicity-${element.id}`}
                      className="collection_check_boxes"
                      htmlFor={`ethnicity-${element.id}`}
                    >
                      {element.name}
                    </label>
                  </div>
                )
              })}
            </div>
            <div>
              <span className="error-text">{ethnicityIdsError}</span>
            </div>
            <div className="row-flex-container align-end">
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="languageId">
                  Test taker's native language? <span className="optional-indicator">(optional)</span>
                </label>
                <div name="languageId" className="test-taker-select-input-container">
                  <Controller
                    as={<Select components={{ DropdownIndicator }} styles={customStyles} options={languages} />}
                    control={control}
                    isSearchable={false}
                    defaultValue={activeLanguage}
                    onChange={([selected]) => {
                      changeActiveLanguage(selected.value)
                      return selected
                    }}
                    // onBlur={validateLanguageId} // BCE-594 | TURN OFF VALIDATION FOR NOW
                    name="languageId"
                    placeholder="Select a native language"
                  />
                </div>
                <div>
                  <span className="error-text">{languageIdError}</span>
                </div>
              </div>
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="educationLevelId">
                  Highest level of education? <span className="optional-indicator">(optional)</span>
                </label>
                <div name="educationLevelId" className="test-taker-select-input-container">
                  <Controller
                    as={<Select components={{ DropdownIndicator }} styles={customStyles} options={educationLevels} />}
                    control={control}
                    isSearchable={false}
                    defaultValue={activeEducation}
                    onChange={([selected]) => {
                      changeActiveEducation(selected.value)
                    }}
                    // onBlur={validateEducationLevelId} // BCE-594 | TURN OFF VALIDATION FOR NOW
                    name="educationLevelId"
                    placeholder="Select an education level"
                  />
                </div>
                <div>
                  <span className="error-text">{educationLevelIdError}</span>
                </div>
              </div>
            </div>
            <div className="row-flex-container">
              <div className="vertical-stack side-by-side-width">
                <label className="test-taker-label" htmlFor="zip_code">
                  Test taker's zip code? <span className="optional-indicator">(optional)</span>
                </label>
                <input
                  className="test-taker-input"
                  value={zipCode}
                  onChange={e => {
                    changeZipCode(e.target.value)
                  }}
                  // onBlur={validateZipCode} // BCE-594 | TURN OFF VALIDATION FOR NOW
                  id="zip-code"
                  name="zip_code"
                />
                <div>
                  <span className="error-text">{zipCodeError}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="action-items">
        <button className={`test-taker-submit ${activeSubmit ? "spin-container" : ""}`} type="submit">
          <div className={`spinning ${activeSubmit ? "" : "hidden"}`}></div>
          {activeSubmit ? "" : "Save"}
        </button>
        <a onClick={openModal} className="cancel-link">
          Cancel
        </a>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          id="close-confirmation-modal"
          className="close-confirmation"
          style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.35)" } }}
        >
          <div className="react-modal-header">
            <h3>Cancel without saving?</h3>
          </div>
          <p className="modal-text">
            Any modifications made to this form that have yet to be saved. Are you sure you want to cancel?
          </p>
          <div className="modal-actions">
            <a className="modal-cancel" onClick={closeModal}>
              No
            </a>
            <button className="modal-confirm" onClick={cancelForm}>
              Yes, cancel
            </button>
          </div>
        </Modal>
      </div>
    </form>
  )
}
export default TestTakerForm
