import React, { useState } from "react"
import "./SideNavigation.scss"
import contactsLogo from "../../../assets/images/contact_details.svg"
import recommendationLogo from "../../../assets/images/recommendation.svg"
import protocolsLogo from "../../../assets/images/protocols.svg"
import { HashRouter, NavLink } from "react-router-dom"

const applyDefaultActive = (match, location) => {
  if (match) return true
  if (location.pathname === "/") {
    return true
  }
  return false
}

export default props => {
  return (
    <div className="sidenav">
      <div className="sidenav__title">Organization Settings</div>
      <HashRouter hashType="noslash">
        <NavLink
          to="contactDetails"
          className="sidenav__item"
          isActive={applyDefaultActive}
          activeClassName="sidenav__item--active"
        >
          <div className="sidenav__item__image">
            <img src={contactsLogo} />
          </div>
          <div className="sidenav__item__text">contact details</div>
        </NavLink>
        <NavLink to="recommendations" className="sidenav__item" activeClassName="sidenav__item--active">
          <div className="sidenav__item__image">
            <img src={recommendationLogo} />
          </div>
          <div className="sidenav__item__text">recommendations</div>
        </NavLink>
        {props.isPrimaryAccountHolder && (
          <NavLink to="protocols" className="sidenav__item" activeClassName="sidenav__item--active">
            <div className="sidenav__item__image">
              <img src={protocolsLogo} />
            </div>
            <div className="sidenav__item__text">protocols</div>
          </NavLink>
        )}
      </HashRouter>
    </div>
  )
}
