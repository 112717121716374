import React, { useState } from "react"
import "./TosForm.scss"

export default function TosForm(props) {
  const [agreedToTOS, setAgreedToTOS] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [displayAlert, setDisplayAlert] = useState({
    open: false,
    success: true,
    message: "",
  })

  const _handleSubmissionSuccess = () => {
    window.location.href = "/"
  }

  const _handleSubmissionFailure = () => {
    setDisplayAlert({
      open: true,
      success: false,
      message: "Failed to save settings",
    })
  }

  const handleSubmit = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        terms_and_conditions: true,
      }),
    }

    fetch(`/api/v1/users/${props.userId}`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        } else if (!response.ok) {
          throw "failed to update user."
        }
      })
      .then(() => {
        _handleSubmissionSuccess()
      })
      .catch(() => {
        _handleSubmissionFailure()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className="tos-greenwall-container">
      <h2>Terms of Use & Privacy Policy</h2>
      {displayAlert.open && (
        <div className="row">
          <div className="col-xs-11">
            <div className={displayAlert.success ? "alert alert-success" : "alert alert-danger"}>
              <div>{displayAlert.message}</div>
              <div
                className="close"
                onClick={() => {
                  setDisplayAlert({ open: false, success: true, message: "" })
                }}
              >
                x
              </div>
            </div>
          </div>
        </div>
      )}
      <label htmlFor="tos-checkbox" className="tos-checkbox-wrapper">
        <input
          type="checkbox"
          name="tos-checkbox"
          checked={agreedToTOS}
          onChange={() => setAgreedToTOS(prevState => !prevState)}
          id="tos-checkbox"
          className="tos-checkbox"
        />
        <span className="tos-checkbox-text">
          I agree to the{" "}
          <a
            className="checkbox-label-link"
            href="https://braincheck.com/terms-of-use/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
          <span className="checkbox-label-text"> & </span>
          <a
            className="checkbox-label-link"
            href="https://braincheck.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </span>
      </label>
      <div className="tos-save-button-container">
        <button
          className={(isSubmitting || !agreedToTOS ? "save-button--disabled" : "save-button") + " tos-save-button"}
          disabled={isSubmitting || !agreedToTOS}
          onClick={handleSubmit}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  )
}
