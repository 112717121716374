import React, { useState, useEffect } from "react"
import "./Protocols.scss"
import ProtocolDropdown from "./protocol_dropdown/ProtocolDropdown"

export default function Form() {
  const [currentlySelectedDefaultProtocolId, setCurrentlySelectedDefaultProtocolId] = useState()
  const [displayAlert, setDisplayAlert] = useState({
    open: false,
    success: true,
    message: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadingProtocols, setIsLoadingProtocols] = useState(true)
  const [initialDefaultProtocolId, setInitialDefaultProtocolId] = useState()
  const [protocols, setProtocols] = useState([])

  useEffect(() => {
    let ignore = false // https://beta.reactjs.org/reference/react/useEffect#fetching-data-with-effects

    const headers = {
      "Content-Type": "application/json",
    }

    fetch("/api/v1/protocol_configurations", { headers })
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        }
        return response.json()
      })
      .then(responseJSON => {
        const protocolsRaw = responseJSON["protocol_configurations"]
          .filter(protocol => !protocol.global)
          .sort((a, b) => (a.id < b.id ? -1 : 1))
        const initialDefaultProtocol = protocolsRaw.find(protocol => protocol.default)
        const initialDefaultProtocolId = initialDefaultProtocol ? initialDefaultProtocol.id : ""
        const protocols = protocolsRaw.map(({ id, name, steps }) => ({ id, name, steps }))

        if (!ignore) {
          setCurrentlySelectedDefaultProtocolId(initialDefaultProtocolId)
          setInitialDefaultProtocolId(initialDefaultProtocolId)
          setProtocols(protocols)
        }
      })
      .finally(() => {
        if (!ignore) {
          setIsLoadingProtocols(false)
        }
      })

    return () => {
      ignore = true
    }
  }, [])

  const handleProtocolSelected = e => {
    if (isSubmitting) return
    // const protocolId = e.target.value !== "" ? e.target.value : null
    setCurrentlySelectedDefaultProtocolId(e.target.value)
  }

  const _handleSubmissionSuccess = newDefaultProtocolId => {
    setInitialDefaultProtocolId(newDefaultProtocolId),
      setDisplayAlert({
        open: true,
        success: true,
        message: "Successfully saved settings",
      })
    setIsSubmitting(false)
  }

  const _handleSubmissionFailure = () => {
    setCurrentlySelectedDefaultProtocolId(initialDefaultProtocolId)
    setDisplayAlert({
      open: true,
      success: false,
      message: "Failed to save settings",
    })
    setIsSubmitting(false)
  }

  const handleSubmit = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const newDefaultProtocol = protocols.find(protocol => protocol.id == currentlySelectedDefaultProtocolId)
    const newDefaultProtocolId = (newDefaultProtocol && newDefaultProtocol.id) || ""

    if (currentlySelectedDefaultProtocolId == initialDefaultProtocolId) {
      return _handleSubmissionSuccess(currentlySelectedDefaultProtocolId)
    }

    const protocolToUpdate =
      newDefaultProtocolId !== ""
        ? { id: currentlySelectedDefaultProtocolId, default: true }
        : { id: initialDefaultProtocolId, default: false } // when the user selects "none" for default protocol, we can unset the default proctol on the server side by sending an api request to update the default protcol's "default" property to false

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        protocol_configuration: {
          default: protocolToUpdate.default,
        },
      }),
    }

    fetch(`/api/v1/protocol_configurations/${protocolToUpdate.id}`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        } else if (!response.ok) {
          throw "failed to update default protocol"
        }
      })
      .then(() => {
        _handleSubmissionSuccess(newDefaultProtocolId)
      })
      .catch(() => {
        _handleSubmissionFailure()
      })
  }

  return (
    <div className="container protocol-settings-container">
      {isLoadingProtocols && <div className="loading"></div>}

      <div className="row">
        <div className="col-xs-8 protocols-title">Protocols</div>
        <div className="col-xs-4">
          <button
            className={(isSubmitting ? "save-button--disabled" : "save-button") + " pull-right"}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? "Saving..." : "Save Settings"}
          </button>
        </div>
      </div>

      {displayAlert.open && (
        <div className="row">
          <div className="col-xs-11">
            <div className={displayAlert.success ? "alert alert-success" : "alert alert-danger"}>
              <div>{displayAlert.message}</div>
              <div
                className="close"
                onClick={() => {
                  setDisplayAlert({ open: false, success: true, message: "" })
                }}
              >
                x
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="protocols-title-container">
        <div className="row">
          <div className="col-xs-12">
            <span className="settings-title">Default Protocol</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <span className="settings-subtitle">
              Select a protocol to make it your default test order for your organization.
            </span>
          </div>
        </div>
      </div>

      <div className="protocols-dropdown-container">
        {!isLoadingProtocols && (
          <div className="row">
            <div className="col-xs-12">
              <ProtocolDropdown
                protocol={null}
                value=""
                checked={currentlySelectedDefaultProtocolId == ""}
                onChange={handleProtocolSelected}
              />
            </div>
          </div>
        )}

        {protocols &&
          protocols.length > 0 &&
          protocols.map(protocol => (
            <div className="row" key={protocol.id}>
              <div className="col-xs-12">
                <ProtocolDropdown
                  protocol={protocol}
                  value={protocol.id}
                  checked={currentlySelectedDefaultProtocolId == protocol.id}
                  onChange={handleProtocolSelected}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
