import "./SelectProvider.css"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Artemis } from "../../helpers/Artemis/Artemis"
import Select from "react-select"
import icnCheckmark from "../../assets/images/icn-checkmark.svg"

export default function SelectProvider({ providers, resultId, changeDisplayCreateProvider, refreshPage }) {
  const { t } = useTranslation()
  const [hasSelected, changeHasSelected] = useState(false)
  const [isDisabled, changeIsDisabled] = useState(true)
  const [isSaved, changeIsSaved] = useState(false)
  const [patchObject, changePatchObject] = useState("")
  const [provider, changeProvider] = useState("")
  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "0.25rem",
      border: "1px solid #979797",
      height: "40px",
    }),
    menuList: provided => ({ ...provided, maxHeight: "140px" }),
    option: (provided, { isFocused, isSelected }) => {
      return {
        ...provided,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected ? "#cd2a8a" : isFocused ? "#f1c8e2" : null,
      }
    },
    placeholder: provided => ({ ...provided, color: "#c1c1c1" }),
    indicatorSeparator: () => ({ display: "none" }),
    valueContainer: provided => ({ ...provided, padding: "0.27em 1rem", height: "40px" }),
  }

  const formatProviders = () => {
    return providers.map(provider => ({
      value: provider.id,
      label: `${provider.name_prefix ? `${provider.name_prefix} ` : ""}${provider.first_name} ${provider.last_name}`,
    }))
  }

  const handleChange = e => {
    if (e === null) {
      restoreDefaults()
      return
    }
    changeProvider(e)
    changeHasSelected(true)
    changePatchObject({ result: { provider_id: e.value } })
    if (isDisabled === true) {
      changeIsDisabled(false)
    }
  }

  const restoreDefaults = () => {
    changeHasSelected(false)
    changeIsDisabled(true)
    changeIsSaved(false)
    changePatchObject("")
    changeProvider("")
  }

  const createProvider = () => {
    restoreDefaults()
    changeDisplayCreateProvider(true)
  }

  const closeModal = () => {
    let modal = $("#select-provider.modal")
    modal.closeModal()
    if (refreshPage) {
      location.reload()
    }
  }

  const assignProvider = () => {
    Artemis.patch(patchObject, `/api/v1/results/${resultId}`)
      .then(() => {
        changeIsSaved(true)
        let aTagNode = $(`#provider-${resultId}`)
        aTagNode.replaceWith(`<span id="provider-${resultId}">${provider.label}</span>`)
        setTimeout(closeModal, 2000)
      })
      .catch(e => console.log("E is: ", e))
  }

  return (
    <div>
      <h5 className="select-provider-header"> {t("selectProvider.title")} </h5>
      <p className="select-provider-text">{t("selectProvider.text")} </p>
      <Select
        className="select-input"
        classNamePrefix="react-select"
        data-testid="select"
        inputId="select-provider"
        placeholder={t("selectProvider.placeholder")}
        name="assign-provider"
        styles={customStyles}
        isClearable
        onChange={e => handleChange(e)}
        menuIsOpen={hasSelected ? false : true}
        options={formatProviders()}
      />
      <div className="select-provider-row">
        <a type="button" onClick={createProvider} className="provider-button-link">
          {t("selectProvider.add")}
        </a>
        <button
          type="button"
          onClick={isSaved ? closeModal : assignProvider}
          className={`provider-button ${isDisabled ? "disabled" : ""}`}
        >
          {t("selectProvider.assign")}
          {isSaved && <img className="confirmation-check" src={icnCheckmark} />}
        </button>
      </div>
    </div>
  )
}
