import React, { useState, useRef } from "react"
import "./ContactDetails.scss"
import LogoPreview from "./logo_preview/LogoPreview"

export default props => {
  const states = props.states
  const initialState = [
    'logo', 'name', 'address1', 'address2', 'city', 'state_abbr', 'zip_code', 'telephone'
  ].reduce((acc, key) => {
    acc[key] =  props.organization.organization[key]
    return acc
  }, {})
  const [organization, setOrganization] = useState(initialState)
  const id = props.organization.organization.id
  const { logo, name, address1, address2, city, state_abbr, zip_code, telephone } = organization

  const fileInput = useRef(null)
  const [fileName, setFileName] = useState("Choose file...")
  const [isLogoLoading, setLogoLoading] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [isUploading, setUploading] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [displayAlert, setDisplayAlert] = useState({ open: false, success: true, message: "" })
  const [dirty, setDirty] = useState(false)

  function fetchOrg (url, options = {}) {
    return fetch(url, options)
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        }
        return response.json()
      })
  }

  function handleChange (e) {
    const { name, value } = e.target

    setDirty(true)
    setOrganization({ ...organization, [name]: value })
  }

  const handleLogoUpload = e => {
    e.preventDefault()
    e.stopPropagation()
    if (fileName === "Choose file..." || isLogoLoading) return

    setLogoLoading(true)
    setUploading(true)

    const formdata = new FormData()

    formdata.append("logo", fileInput.current.files[0])

    fetchOrg(`/api/v1/organizations/${id}`, { method: "PATCH", body: formdata })
      .then(result => {
        fileInput.current.value = null
        setFileName("Choose file...")
        if (result.errors) {
          setDisplayAlert({ ...displayAlert, open: true, success: false, message: errorFormatting(result.errors) })
          return
        }
        setOrganization({ ...organization, logo: result.logo })
        setDisplayAlert({ ...displayAlert, open: true, success: true, message: "Successfully updated organization" })
      })
      .finally(() => {
        setLogoLoading(false)
        setUploading(false)
      })
  }

  const handleDelete = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!logo || !logo.url || isLogoLoading) return
    setLogoLoading(true)
    setDeleting(true)

    fetchOrg(`/api/v1/organization_logos/${id}`, { method: "DELETE" })
      .then(result => {
        if (result.errors) {
          setDisplayAlert({ ...displayAlert, open: true, success: false, message: errorFormatting(result.errors) })
          return
        }
        setOrganization({ ...organization, logo: { url: null } })
        setDisplayAlert({ ...displayAlert, open: true, success: true, message: "Successfully removed logo" })
      })
      .finally(() => {
        setLogoLoading(false)
        setDeleting(false)
      })
  }

  const handleFileInputChange = () => {
    if (fileInput.current.files && fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name)
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (isFormSubmitting || isLogoLoading) return
    const myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    setIsFormSubmitting(true)
    fetchOrg(`/api/v1/organizations/${id}`, { method: "PATCH", headers: myHeaders, body: JSON.stringify(organization) })
      .then(result => {
        if (result.errors) {
          setDisplayAlert({ ...displayAlert, open: true, success: false, message: errorFormatting(result.errors) })
          return
        }
        setDisplayAlert({ ...displayAlert, open: true, success: true, message: "Successfully updated organization" })
      })
      .finally(() => {
        setLogoLoading(false)
        setUploading(false)
        setIsFormSubmitting(false)
        setDirty(false)
      })
  }

  /**
   * Format the error object to be a single string
   * @param {*} error will be a object like {errorName: [messsages]}
   */
  const errorFormatting = errors => {
    if (!errors) return ""
    let message = ""
    for (const key in errors) {
      message += key.charAt(0).toUpperCase() + key.slice(1) + " "
      errors[key].forEach(error => (message += error))
    }
    return message
  }

  return (
    <form onSubmit={handleFormSubmit} id="edit">
      <div className="container">
        <div className="row">
          <div className="col s8 contact-title">Contact Details</div>
          <div className="col s4">
            <button
              className={isLogoLoading || isFormSubmitting || !dirty ? "save-button--disabled" : "save-button"}
              disabled={isLogoLoading || isFormSubmitting || !dirty}
              type="submit"
              form="edit"
            >
              {isLogoLoading || isFormSubmitting ? "Saving..." : "Save Settings"}
            </button>
          </div>
        </div>
        {displayAlert.open && (
          <div className="row">
            <div className="col s11">
              <div className={displayAlert.success ? "alert alert-success" : "alert alert-danger"}>
                <div>{displayAlert.message}</div>
                <div
                  className="close"
                  onClick={() => setDisplayAlert({ ...displayAlert, open: false, success: false, message: "" })}
                >
                  x
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="section-title contact-row">Organization Logo</div>
        <div className="contact-row--margin-5">
          <div className="contact-column">
            <LogoPreview isLogoLoading={isLogoLoading} logo={logo} />
          </div>

          <div className="contact-column--reverse section-title">Practice/Organization Name</div>
        </div>
      
        <div className="contact-row--margin-10">
          <div className="contact-column file">
            <input
              onChange={handleFileInputChange}
              type="file"
              id="file"
              accept="image/png,image/gif,image/jpeg"
              ref={fileInput}
            />
            <span className="file-custom"></span>
            <div className="file-custom--src">{fileName}</div>
          </div>
          <div className="contact-column form-input-container--special">
            <input
              required
              className="contact-form-input"
              type="text"
              placeholder="Practice/Organization Name"
              value={name || ""}
              name="name"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="contact-row--margin-10">
          <div className="contact-column">
            <button
              onClick={handleLogoUpload}
              className={fileName !== "Choose file..." && !isLogoLoading ? "upload-button" : "upload-button--disabled"}
              disabled={fileName === "Choose file..." || isLogoLoading}
            >
              {isUploading ? "Uploading..." : "Upload"}
            </button>
          </div>
          <div className="contact-column">
            {logo && logo.url && !isLogoLoading && (
              <button
                onClick={handleDelete}
                className={
                  logo && logo.url && !isLogoLoading
                    ? "upload-button"
                    : "upload-button--disabled"
                }
                disabled={!(logo && logo.url && !isLogoLoading)}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            )}
          </div>
        </div>

        <div className="contact-row--margin-20">
          <div className="section-title contact-column">
            Street Address
            <div className="form-input-container">
              <input
                className="contact-form-input"
                type="text"
                placeholder="Street Address"
                value={address1 || ""}
                name="address1"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="section-title contact-column--row-direction">
            <div className="half-section">
              Suite/Unit
              <div className="form-input-container--small">
                <input
                  className="contact-form-input"
                  type="text"
                  placeholder="Street Address"
                  value={address2 || ""}
                  name="address2"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="contact-row--margin-20">
          <div className="section-title contact-column">
            City
            <div className="form-input-container">
              <input
                className="contact-form-input"
                type="text"
                placeholder="Street Address"
                value={city || ""}
                name="city"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="section-title contact-column--row-direction">
            <div className="half-section">
              State
              <div className="form-input-container--small">
                <select
                  defaultValue={state_abbr}
                  name="state_abbr"
                  onChange={handleChange}
                >
                  {states.map(([label, value]) => <option key={value} value={value}>{label}</option>)}
              </select>
              </div>
            </div>
            <div className="half-section">
              <span className="margin-left-10">Zip Code</span>
              <div className="form-input-container--small">
                <input
                  className="zipcode-input"
                  type="text"
                  placeholder="Street Address"
                  value={zip_code || ""}
                  name="zip_code"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="contact-row--margin-20">
          <div className="section-title contact-column">
            Phone Number
            <div className="form-input-container">
              <input
                className="contact-form-input"
                type="text"
                placeholder="Phone Number"
                value={telephone || ""}
                name="telephone"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
