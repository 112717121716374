const batteries = [
  {
    step_name: "Memory",
    step_title: "Standard Battery",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 4,
    step_type_id: 2,
    step_duration_min: 8,
    step_duration_max: 15,
    step_active: false,
    self_administered: true,
    step_assessments: "Immediate & delayed recognition, Trails A/B, Stroop, Digit-symbol substitution",
  },
  {
    step_name: "Student Concussion",
    step_title: "Extended Battery",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 6,
    step_type_id: 1,
    step_duration_min: 10,
    step_duration_max: 20,
    step_active: false,
    self_administered: true,
    step_assessments:
      "Immediate & delayed recognition, Trails A/B, Stroop, Digit-symbol substitution, Flanker, Coordination",
  },
  {
    step_name: "Research II",
    step_title: "Research II Battery",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 8,
    step_type_id: 13,
    step_duration_min: 25,
    step_duration_max: 35,
    step_active: false,
    self_administered: true,
    step_assessments:
      "Immediate Recognition, Trails A/B, Stroop, Digit Symbol, Delayed Recognition, Flanker, Coordination, Matrices, Boston Naming, Animal Naming",
  },
  {
    step_name: "Research II Part A",
    step_title: "Research II Battery Part A",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 7,
    step_type_id: 14,
    step_duration_min: 10,
    step_duration_max: 20,
    step_active: false,
    self_administered: true,
    step_assessments:
      "Immediate Recognition, Trails A/B, Stroop, Digit Symbol, Delayed Recognition, Flanker, Coordination",
  },
  {
    step_name: "Research II Part B",
    step_title: "Research II Battery Part B",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 2,
    step_type_id: 15,
    step_duration_min: 5,
    step_duration_max: 15,
    step_active: false,
    self_administered: true,
    step_assessments: "Matrices, Boston Naming, Animal Naming",
  },
  {
    step_name: "Animal Naming",
    step_title: "Animal Naming",
    step_type: "batteries",
    step_target: "unity",
    step_test_count: 1,
    step_type_id: 11,
    step_duration_min: 5,
    step_duration_max: 15,
    step_active: false,
    self_administered: true,
    step_assessments: "Austin Naming, Animal Naming",
  },
]

const screeners = [
  {
    step_name: "iqcode",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The informant Questionnaire on Cognitive Decline in the Elderly (IQCODE) screening tool is a short questionnaire designed to assess cognitive decline in elderly people.",
    step_title: "Short IQCODE Test",
    step_type_id: 3,
    step_duration_min: 2,
    step_duration_max: 4,
    step_active: false,
    self_administered: true,
    step_category: "cognitive",
  },
  {
    step_name: "gad_7",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Generalized Anxiety Disorder 7 (GAD-7) test is a self-reported questionnaire for screening and severity measuring of generalized anxiety disorder (GAD).",
    step_title: "GAD7",
    step_type_id: 4,
    step_duration_min: 1,
    step_duration_max: 2,
    step_active: false,
    self_administered: true,
    step_category: "anxiety",
  },
  {
    step_name: "phq_9",
    step_type: "screeners",
    step_target: "unity",
    step_description:
      "The Patient Health Questionnaire 9 is a 9-question instrument given to patients in a primary care setting to screen for the presence and severity of depression.",
    step_title: "PHQ9",
    step_type_id: 5,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "mood",
  },
  {
    step_name: "gds",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Geriatric Depression Scale Short Form is a 15-question screening tool for depression in older adults that takes five to seven minutes to complete and can be filled out by the patient or administered by a provider with minimal training in its use.",
    step_title: "GDS",
    step_type_id: 6,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "mood",
  },
  {
    step_name: "pcl5",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This post-traumatic stress disorder checklist asks about problems a patient might have after a very stressful experience",
    step_title: "PCL-5",
    step_type_id: 7,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ptsd",
  },
  {
    step_name: "cis-survey",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Convergence Insufficiency Symptom Survey asks patients to answer questions regarding how their eyes feel when reading or doing close work",
    step_title: "CISS",
    step_type_id: 8,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
    step_category: "vision",
  },
  {
    step_name: "hit6",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The evaluation of headache disability questionnaire is designed to help patients describe and communicate how headaches affect them",
    step_title: "HIT-6",
    step_type_id: 116,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "tbi",
  },
  {
    step_name: "perceived-stress-scale",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Perceived Stress Scale is a questionnaire that asks questions of patients about their own perception of stress",
    step_title: "PSS",
    step_type_id: 10,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "stress",
  },
  {
    step_name: "rivermead-questionnaire",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Rivermead Post-Concussion Symptom Questionnaire is used to measure the severity of symptoms after a head injury",
    step_title: "RPQ",
    step_type_id: 11,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "tbi",
  },
  {
    step_name: "dizziness-handicap-inventory",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Dizziness Handicap Inventory is a self-reported questionnaire that helps evaluate the effects dizziness might have on a patient",
    step_title: "DHI",
    step_type_id: 12,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "tbi",
  },
  {
    step_name: "extended-glasgow-outcome-scale",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The extended Glasgow Outcome Scale was developed as a structured interview after patients have had a head injury",
    step_title: "GOS-E",
    step_type_id: 13,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
    step_category: "tbi",
  },
  {
    step_name: "caregiver-profile",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the caregiver of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "Caregiver Profile",
    step_type_id: 14,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ccp",
  },
  {
    step_name: "daily-activities-screener",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the caregiver of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "Daily Activities Screener",
    step_type_id: 16,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ccp",
  },
  {
    step_name: "decision-making-capacity-scale",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the test administrator of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "Decision Making Capacity Scale",
    step_type_id: 17,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
    step_category: "ccp",
  },
  {
    step_name: "dementia-severity-rating",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the caregiver of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "Dementia Severity Rating",
    step_type_id: 18,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ccp",
  },
  {
    step_name: "end-of-life-checklist",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the caregiver of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "End Of Life Checklist",
    step_type_id: 19,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ccp",
  },
  {
    step_name: "safety-assessment",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This is to be taken by the caregiver of the patient being assessed. Used in the BrainCheck Cognitive Care Plan.",
    step_title: "Safety Assessment",
    step_type_id: 20,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "ccp",
  },
  {
    step_name: "SLUMS",
    step_type: "screeners",
    step_target: "unity",
    step_description:
      "The Saint Louis University Mental Status Examination (SLUMS) is a brief oral/written exam given to people that are suspected to have dementia or Alzheimer's Disease. The exam serves as a tool to indicate whether a doctor should consider further testing to diagnose dementia.",
    step_title: "SLUMS Test",
    step_type_id: 15,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
    step_category: "cognitive",
  },
  {
    step_name: "ClockDrawing",
    step_type: "screeners",
    step_target: "unity",
    step_description:
      "The clock-drawing test is used for screening for cognitive impairment and dementia and as a measure of spatial dysfunction and neglect.",
    step_title: "Clock Drawing Test",
    step_type_id: 22,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
    step_category: "cognitive",
  },
  {
    step_name: "opioid-use",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Opioid Risk Tool (ORT) is a brief, self-report screening tool designed for use with adult patients in primary care settings to assess risk for opioid abuse among individuals prescribed opioids for treatment of chronic pain.",
    step_title: "Opioid Use",
    step_type_id: 24,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "other",
  },
  {
    step_name: "adhd",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This checklist consists of eighteen criteria. Questions 1-6 (Part A) were found to be the most predictive of symptoms consistent with ADHD. Questions 7-18 (Part B) contains the remaining twelve question, that provide more context into the patient's symptoms",
    step_title: "ADHD Symptom Screener",
    step_type_id: 25,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_category: "other",
  },
  {
    step_name: "pareidolia",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "The Pareidolia assessment contains one part with 10 trials. For each trial we ask the test taker to indicate whether they see a face in the presented object, or not.",
    step_title: "Pareidolia",
    step_type_id: 26,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_clinical_trial: true,
    step_category: "cognitive",
  },
  {
    step_name: "visuospatial-2d",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      'The Visuospatial 2D assessment contains 10 trials. For each task, we provide 2 objects, and ask the test taker to answer "Yes" or "No" for whether the presented objects are the same or different with certain rotations.',
    step_title: "Visuospatial 2D",
    step_type_id: 27,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_clinical_trial: true,
    step_category: "cognitive",
  },
  {
    step_name: "visuospatial-3d",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      'The Visuospatial 3D assessment contains 12 trials. For each task, we provide 2 objects, and ask the test taker to answer "Yes" or "No" for whether the presented objects are the same or different with certain rotations.',
    step_title: "Visuospatial 3D",
    step_type_id: 28,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
    step_clinical_trial: true,
    step_category: "cognitive",
  },
  {
    step_name: "tsat",
    step_type: "screeners",
    step_target: "ember",
    step_description:
      "This technician administered screener can be used to gather information about the Test Taker’s experience. It includes two questions and a free form comment box.",
    step_title: "Test Taker Satisfaction Screener",
    step_type_id: 29,
    step_duration_min: 1,
    step_duration_max: 2,
    step_active: false,
    self_administered: true,
    step_clinical_trial: false,
    step_category: "other",
  },
]

const clockDrawings = [
  {
    step_name: "ClockDrawingUnscored",
    step_type: "clockdrawings",
    step_target: "unity",
    step_description:
      "The clock-drawing test is used for screening for cognitive impairment and dementia and as a measure of spatial dysfunction and neglect.",
    step_title: "Clock Drawing Test",
    step_type_id: 10001,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: true,
  },
  {
    step_name: "ClockDrawingScoring",
    step_type: "clockdrawings",
    step_target: "unity",
    step_description:
      "The clock-drawing test is used for screening for cognitive impairment and dementia and as a measure of spatial dysfunction and neglect.",
    step_title: "Clock Drawing Test",
    step_type_id: 10002,
    step_duration_min: 1,
    step_duration_max: 3,
    step_active: false,
    self_administered: false,
  },
]

const protocolLists = {
  batteries: batteries,
  screeners: screeners,
  clockdrawings: clockDrawings,
}

const getProtocols = (protocolSteps, stepsType) => {
  const steps = protocolSteps.filter(step => step.step_type === stepsType)
  const stepIds = steps.map(s => s.step_type_id)
  return protocolLists[stepsType].filter(s => stepIds.includes(s.step_type_id))
}

export const getProtocolBatteries = protocolSteps => {
  return getProtocols(protocolSteps, "batteries")
}

export const getProtocolScreeners = protocolSteps => {
  return getProtocols(protocolSteps, "screeners")
}

export const getProtocolClockDrawings = protocolSteps => {
  return getProtocols(protocolSteps, "clockdrawings")
}

const getDuration = (protocolSteps, stepDurationType) => {
  return protocolSteps.reduce((totalDuration, protocolStep) => {
    return totalDuration + protocolStep[stepDurationType]
  }, 0)
}

export const getMinimumDuration = protocolSteps => {
  return getDuration(protocolSteps, "step_duration_min")
}

export const getMaximumDuration = protocolSteps => {
  return getDuration(protocolSteps, "step_duration_max")
}
