import React from "react"
import "./Recommendations.scss"

export default class Recommendations extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      pageLoading: true,
      recommendations: [],
      displayAlert: {
        open: false,
        success: true,
        message: "",
      },
      isFormSubmitting: false,
    }
    this.formattingReponse = this.formattingReponse.bind(this)
    this.handleOptionChose = this.handleOptionChose.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    // Remove previous event handlers and add new ones:
    // should be idempotent
    ReactRailsUJS.detectEvents()

    this._isMounted = true

    const myHeaders = new Headers()
    const requestOptions = {
      method: "GET",
    }
    myHeaders.append("Content-Type", "application/json")
    fetch(`/recommendations`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        }
        return response.json()
      })
      .then(object => {
        this.formattingReponse(object)
        if (this._isMounted) {
          this.setState(prevState => {
            return {
              recommendations: [...prevState.recommendations, ...object],
            }
          })
        }
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState(prevState => ({
            ...prevState,
            pageLoading: false,
          }))
        }
      })
  }

  formattingReponse(objectArray) {
    objectArray.map(object => {
      const options = object.options.map(option => {
        const formatted = {}
        formatted[option.name] = option.value
        return formatted
      })
      object.formattedOptions = options
    })
  }

  handleOptionChose(e) {
    const recommendationId = parseInt(e.target.id.split(":")[0], 10)
    const optionId = parseInt(e.target.id.split(":")[1], 10)
    const optionName = e.target.name

    this.setState(prevState => ({
      recommendations: prevState.recommendations.map(recommendation => {
        return recommendation.id === recommendationId
          ? {
              ...recommendation,
              formattedOptions: recommendation.formattedOptions.map((option, index) => {
                if (index === optionId) {
                  let newOption = { ...option }
                  newOption[optionName] = !newOption[optionName]
                  return newOption
                }
                return option
              }),
              options: recommendation.options.map((option, index) => {
                return index === optionId
                  ? {
                      ...option,
                      value: !option.value,
                    }
                  : option
              }),
            }
          : recommendation
      }),
    }))
  }

  handleFormSubmit() {
    if (this.state.isFormSubmitting) return

    this.setState(prevState => ({
      ...prevState,
      isFormSubmitting: true,
    }))

    const settings = this.state.recommendations.map(recommendation => {
      const setting = {}
      setting.recommendation_form_id = recommendation.id
      const options = {}
      recommendation.options.forEach(option => {
        options[option.label] = `${option.value}`
      })
      setting.options = options
      return setting
    })

    const myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    const requestBody = {
      recommendation_settings: {
        organization_id: this.props.organization.organization.id,
        settings: settings,
      },
    }

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({ ...requestBody }),
    }

    fetch(`/api/v1/recommendation_settings`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          window.location.href = "/users/sign_in"
        }
        return response.json()
      })
      .then(() => {
        this.setState(prevState => ({
          ...prevState,
          displayAlert: {
            open: true,
            success: true,
            message: "Successfully saved settings",
          },
        }))
      })
      .catch(() => {
        this.setState(prevState => ({
          ...prevState,
          displayAlert: {
            open: true,
            success: false,
            message: "Failed to save settings",
          },
        }))
      })
      .finally(() => {
        this.setState(prevState => ({
          ...prevState,
          isFormSubmitting: false,
        }))
      })
  }

  render() {
    return (
      <div className="container">
        {this.state.pageLoading && <div className="loading"></div>}
        <div className="row">
          <div className="col s8 recommendations-title">Recommendations</div>

          <div className="col s4">
            <button
              className={this.state.isFormSubmitting ? "save-button--disabled" : "save-button"}
              disabled={this.state.isFormSubmitting}
              onClick={this.handleFormSubmit}
            >
              {this.state.isFormSubmitting ? "Saving..." : "Save Settings"}
            </button>
          </div>
        </div>
        {this.state.displayAlert.open && (
          <div className="row">
            <div className="col s11">
              <div className={this.state.displayAlert.success ? "alert alert-success" : "alert alert-danger"}>
                <div>{this.state.displayAlert.message}</div>
                <div
                  className="close"
                  onClick={() => {
                    this.setState(prevState => ({
                      ...prevState,
                      displayAlert: { open: false, success: true, message: "" },
                    }))
                  }}
                >
                  x
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.recommendations && this.state.recommendations.length > 0 && (
          <div>
            {this.state.recommendations.map(recommendation => (
              <div key={recommendation.id}>
                <div className="row">
                  <div className="col s6">
                    <span className="settings-title">{recommendation.title}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s6">
                    <div className="sample-container">
                      <span className="sample-title">Sample recommendations:</span>
                      <img className="sample-image" src={recommendation.image}></img>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col s6">{recommendation.description}</div>
                </div>

                <div>
                  {recommendation.options.map((option, optionId) => (
                    <div className="row" key={optionId}>
                      <div className="col s6">
                        <input
                          type={option.type}
                          className={option.type}
                          id={`${recommendation.id}:${optionId}`}
                          name={option.name}
                          checked={recommendation.formattedOptions[optionId][option.name]}
                          onChange={this.handleOptionChose}
                        />
                        <label className="collection_check_boxes" htmlFor={`${recommendation.id}:${optionId}`}>
                          {option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
