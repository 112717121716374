import React, { useState } from "react"
import "./OrganizationSettings.scss"
import SideNavigation from "./side_navigation/SideNavigation"
import ContactDetails from "./contact_details/ContactDetails"
import Recommendations from "./recommendations/Recommendations"
import Protocols from "./protocols/Protocols"
import { BrowserRouter as Router, Route } from "react-router-dom"

const HashRoute = ({ component: Component, path, ...routeProps }) => (
  <Route {...routeProps} component={({ location, ...props }) => location.hash === path && <Component {...props} />} />
)

export default props => {
  return (
    <div className="organization-container">
      <div>
        <SideNavigation isPrimaryAccountHolder={props.isPrimaryAccountHolder} />
      </div>
      <div className="organization-content">
        <Router>
          <HashRoute exact path="" component={() => <ContactDetails {...props} />} />
          <HashRoute exact path="#contactDetails" component={() => <ContactDetails {...props} />} />
          <HashRoute exact path="#recommendations" component={() => <Recommendations {...props} />} />
          {props.isPrimaryAccountHolder && (
            <HashRoute exact path="#protocols" component={() => <Protocols {...props} />} />
          )}
        </Router>
      </div>
    </div>
  )
}
