import React from "react"
import "./LogoPreview.scss"
import EmptyLogo from "../../../../assets/images/empty-logo.svg"

export default ({ isLogoLoading, logo }) => {
  if (isLogoLoading) {
    return (
      <div className="logo-preview">
        <div className="loader"></div>
      </div>
    )
  }
  return (
    <div className="logo-preview">
      {logo && logo.url ? (
        <div>
          <img src={logo.url} alt="" />
        </div>
      ) : (
        <div>
          <div className="logo-preview__image">
            <img className="empty-logo" src={EmptyLogo} />
          </div>
          <div className="logo-preview__text">
            JPG, JPEG or PNG. Max size of 800k. Dimension suggestion: 150px * 50px
          </div>
        </div>
      )}
    </div>
  )
}
