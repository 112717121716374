const handleAdvance = (requiredFields, changeFunction) => {
  if (
    Object.keys(requiredFields).every(k => {
      return requiredFields[k] === true
    })
  ) {
    changeFunction(false)
  } else {
    changeFunction(true)
  }
}

export default handleAdvance
