import { fireEvent, render, screen } from "@testing-library/react"
import TextInput from "."

let mockPostObject = null
let mockChangeRequiredFields = null

beforeEach(() => {
  mockPostObject = jest.fn()
  mockChangeRequiredFields = jest.fn()
})

function renderTextInput(required) {
  render(
    <TextInput
      labelText="Label Foo"
      inputName="bar"
      changePostObject={mockPostObject}
      changeRequiredFields={mockChangeRequiredFields}
      placeHolderText="Placeholder bar"
      required={required}
    />
  )
}

test("renders TextInput", () => {
  renderTextInput(true)
  const elementLabel = screen.getByText(/Label Foo/i)
  expect(elementLabel).toBeInTheDocument()
})

test("renders required asterisk when required", () => {
  renderTextInput(true)
  const requiredElement = screen.queryByTestId("required-indication")
  expect(requiredElement).toBeInTheDocument()
})

test("does not render required asterisk when not required", () => {
  renderTextInput(false)
  const requiredElement = screen.queryByTestId("required-indication")
  expect(requiredElement).not.toBeInTheDocument()
})

test("adds error classes when required, but left blank", async () => {
  renderTextInput(true)
  const textLabel = screen.getByTestId("text-label")
  const requiredElement = screen.getByTestId("required-indication")
  const textInput = screen.getByTestId("text-input")

  textInput.focus()
  textInput.blur()

  expect(textLabel).toHaveClass("error")
  expect(requiredElement).toHaveClass("error")
  expect(textInput).toHaveClass("error")
})

test("adds error classes and then removes error classes", async () => {
  renderTextInput(true)
  const textLabel = screen.getByTestId("text-label")
  const requiredElement = screen.getByTestId("required-indication")
  const textInput = screen.getByTestId("text-input")

  textInput.focus()
  textInput.blur()

  expect(textLabel).toHaveClass("error")
  expect(requiredElement).toHaveClass("error")
  expect(textInput).toHaveClass("error")

  textInput.focus()

  fireEvent.change(textInput, { target: { value: "Some value" } })

  textInput.blur()

  expect(textLabel).not.toHaveClass("error")
  expect(requiredElement).not.toHaveClass("error")
  expect(textInput).not.toHaveClass("error")
})

test("works without a changePostObject", async () => {
  render(
    <TextInput
      labelText="Label Foo"
      inputName="bar"
      changeRequiredFields={mockChangeRequiredFields}
      placeHolderText="Placeholder bar"
      required
    />
  )
  const textLabel = screen.getByTestId("text-label")
  const requiredElement = screen.getByTestId("required-indication")
  const textInput = screen.getByTestId("text-input")

  textInput.focus()
  textInput.blur()

  expect(textLabel).toHaveClass("error")
  expect(requiredElement).toHaveClass("error")
  expect(textInput).toHaveClass("error")

  textInput.focus()

  fireEvent.change(textInput, { target: { value: "Some value" } })

  textInput.blur()

  expect(textLabel).not.toHaveClass("error")
  expect(requiredElement).not.toHaveClass("error")
  expect(textInput).not.toHaveClass("error")
})

test("works without being required", async () => {
  render(
    <TextInput
      labelText="Label Foo"
      inputName="bar"
      changeRequiredFields={mockChangeRequiredFields}
      placeHolderText="Placeholder bar"
    />
  )
  const textLabel = screen.getByTestId("text-label")
  const textInput = screen.getByTestId("text-input")

  textInput.focus()
  textInput.blur()

  expect(textLabel).not.toHaveClass("error")
  expect(textInput).not.toHaveClass("error")

  textInput.focus()

  fireEvent.change(textInput, { target: { value: "Some value" } })

  textInput.blur()

  expect(textLabel).not.toHaveClass("error")
  expect(textInput).not.toHaveClass("error")
})
