import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  en: {
    translation: {
      createProvider: {
        addAndAssign: "Add and Assign Provider",
        back: "Back to Search",
        text: "You have chosen to Add a New Provider. Enter the details for this new Provider below:",
        formFields: {
          title: "Title (optional)",
          firstName: "First Name",
          lastName: "Last Name",
        },
        formPlaceholders: {
          title: "Dr.",
          firstName: "First Name",
          lastName: "Last Name",
        },
      },
      selectProvider: {
        add: "Add New Provider",
        assign: "Assign Provider",
        placeholder: "Begin typing Provider name here",
        text:
          "Assigning a provider to a test helps keep clean patient records. Select a provider from the dropdown below or add a new Provider and assign them to this test. ",
        title: "Assign a Provider to this test",
      },
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
